import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const StyledGrid = styled.div<{ columns: number; rows: number; height: 'small' | 'large' }>(
    ({ theme, columns, rows, height }) => ({
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, ${
            height === 'small' ? `${theme.moduleHeight.small}px` : '220px'
        })`,
        gap: theme.spaces[7],
        [breakpoints.sm]: {
            gridTemplateRows: `repeat(${rows}, ${height === 'small' ? '28vw' : '56vw'})`,
        },
        [breakpoints.lg]: {
            gridTemplateRows: `repeat(${rows}, ${theme.moduleHeight[height]}px)`,
        },
    }),
    ifProp(
        { columns: 2 },
        {
            gridTemplateColumns: `1fr`,
            [breakpoints.sm]: {
                gridTemplateColumns: `repeat(2, 1fr)`,
            },
        }
    )
);

export const StyledCarouselButtonContainer = styled.span<{ alignment: 'left' | 'right' }>(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: theme.zIndexes[1],
        [breakpoints.sm]: {
            transition: `opacity ${theme.animations.timingShort} ease-out`,
            opacity: 0,
        },
    }),
    ifProp(
        { alignment: 'left' },
        {
            left: 10,
            [breakpoints.lg]: {
                left: 20,
            },
        },
        {
            right: 10,
            [breakpoints.lg]: {
                right: 20,
            },
        }
    )
);

export const StyledRelativeContainer = styled.div(() => ({
    position: 'relative',
}));

export const StyledCarouselWrapper = styled.div(() => ({
    position: 'relative',
    overflow: 'hidden',

    [`${StyledCarouselButtonContainer}`]: {
        opacity: 1,
    },
    '.swiper-slide': {
        width: 'auto',
    },
    '.swiper': {
        overflow: 'visible',
    },

    [breakpoints.md]: {
        [`${StyledCarouselButtonContainer}`]: {
            opacity: 0,
        },
        [`&:hover ${StyledCarouselButtonContainer}`]: {
            opacity: 1,
        },
    },
}));
