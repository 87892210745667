import VideoPlayerLazy from 'react-player/lazy';
import { useTheme } from '@emotion/react';
import { M70MediaItem } from '~/lib/data-contract';
import { Image } from '$shared/components';
import {
    StyledImageWrapper,
    StyledMediaItem,
    StyledVideoWrapper,
    StyledVideoInnerWrapper,
} from './styled';
import { useTranslation } from '$shared/utils/translation';
import { useInView } from 'react-cool-inview';
import { useGalleryModal } from '~/shared/hooks/useGalleryModal/useGalleryModalState';
import { CursorFollowButton } from '../CursorFollowButton';
import { useMemo } from 'react';

export type MediaItemProps = {
    data: M70MediaItem;
    isCarousel: boolean;
    height: 'small' | 'large';
    onClick?: () => void;
    disabledCursorMovement?: boolean;
    hideShowGalleryButton?: boolean;
};

export const MediaItem = ({
    data,
    isCarousel = false,
    height,
    onClick,
    disabledCursorMovement,
    hideShowGalleryButton,
}: MediaItemProps) => {
    const { moduleHeight } = useTheme();
    const { visible } = useGalleryModal();
    const { translate } = useTranslation();
    const { observe, inView } = useInView({
        rootMargin: '-100px 0px 0px 0px',
    });
    const ratio = data?.image?.ratio || 1.77;

    const CursorElement = useMemo(() => {
        const title = data.externalVideoSrc
            ? translate('Kompan.Generic.Play')
            : translate('Kompan.Generic.ShowGallery');
        return (
            <CursorFollowButton
                showAlways={!!onClick && !disabledCursorMovement}
                children={title}
                disableMovement={isCarousel || disabledCursorMovement}
            />
        );
    }, [data.externalVideoSrc, isCarousel]);

    const getMediaByType = () => {
        switch (data.image.type) {
            case 'image':
                return (
                    <StyledImageWrapper
                        isCarousel={isCarousel}
                        width={moduleHeight[height] / ratio}
                        onClick={() => !hideShowGalleryButton && onClick && onClick()}
                    >
                        {data.image.src && (
                            <Image
                                src={data.image.src}
                                alt={data.image.name}
                                // height="100%"
                                height="100"
                                width={moduleHeight[height] / ratio}
                                css={{
                                    cursor: !hideShowGalleryButton ? 'pointer' : 'default',
                                }}
                                objectFit="cover"
                                layout="fill"
                                draggable={false}
                                imageFocalPoint={data?.image?.imageFocalPoint}
                            />
                        )}

                        {!hideShowGalleryButton && CursorElement}
                    </StyledImageWrapper>
                );
            case 'file':
                return (
                    <StyledVideoWrapper isCarousel={isCarousel}>
                        <StyledVideoInnerWrapper
                            isCarousel={isCarousel}
                            width={moduleHeight[height] / ratio}
                            onClick={() => !hideShowGalleryButton && onClick && onClick()}
                        >
                            {data.image.src && inView && (
                                <VideoPlayerLazy
                                    key={data.image.src}
                                    url={data.image.src}
                                    playing={!visible}
                                    muted={true}
                                    loop={true}
                                    height="100%"
                                    width="100%"
                                />
                            )}
                        </StyledVideoInnerWrapper>
                    </StyledVideoWrapper>
                );
            default:
                return <p>Not implemedted</p>;
        }
    };

    if (!data.image?.type) return null;

    return (
        <StyledMediaItem
            height={height}
            isCarousel={isCarousel}
            hideShowGalleryButton={hideShowGalleryButton}
            ref={observe}
        >
            {getMediaByType()}
        </StyledMediaItem>
    );
};
