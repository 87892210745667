import { Gutter, MaxWidth, ModuleHeader, ModalGallery } from '$shared/components';
import React, { Fragment, useId, useMemo, useRef, useState } from 'react';
import { M70MediaModule } from '~/lib/data-contract';
import { ModuleContainer } from '../ModuleContainer';
import { MediaItem } from './components/MediaItem';
import {
    StyledGrid,
    StyledCarouselWrapper,
    StyledCarouselButtonContainer,
    StyledRelativeContainer,
} from './styled';
import { weakKey } from '~/shared/utils/jsx';
import { useGalleryModal } from '~/shared/hooks/useGalleryModal/useGalleryModalState';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import SwiperCore, { SwiperOptions } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiperHelper } from '~/shared/hooks/useSwiperHelper/useSwiperHelper';
import { CarouselButton } from '~/shared/components/Carousel/components/CarouselButton/CarouselButton';

export type M70MediaProps = M70MediaModule;

export const M70Media = ({
    headline,
    mediaItems = [],
    columns = 1,
    height = 'large',
    isCarousel = false,
    pageElementIndex,
    ...rest
}: M70MediaProps) => {
    const { show } = useGalleryModal();
    const moduleWidthRef = useRef<HTMLDivElement>(null);
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | undefined>();
    const { hasNext, hasPrev, slideNext, slidePrev } = useSwiperHelper(swiperInstance);

    const galleryId = useId();

    const numberOfRows = useMemo(() => {
        if (!mediaItems) return 0;

        return Math.round(mediaItems.length / columns);
    }, [mediaItems]);

    const mediaComponents = useMemo(() => {
        return mediaItems.map((media) => (
            <MediaItem
                data={media}
                key={weakKey(media)}
                isCarousel={isCarousel}
                height={height}
                onClick={() => {
                    show(galleryId, media.id);
                }}
                disabledCursorMovement={columns != 1 || height == 'small'}
                hideShowGalleryButton={
                    columns === 1 &&
                    !isCarousel &&
                    mediaItems?.length === 1 &&
                    !media.externalVideoSrc
                }
            />
        ));
    }, [mediaItems, columns]);

    return mediaItems.length > 0 ? (
        <ModuleContainer fullWidth hasGutter={false} pageElementIndex={pageElementIndex} {...rest}>
            {isCarousel ? (
                <Fragment>
                    {headline && (
                        <MaxWidth>
                            <Gutter>
                                <ModuleHeader headline={headline} />
                            </Gutter>
                        </MaxWidth>
                    )}
                    <StyledCarouselWrapper ref={moduleWidthRef}>
                        <MaxWidth>
                            <Gutter>
                                <StyledRelativeContainer>
                                    {hasPrev && (
                                        <StyledCarouselButtonContainer alignment="left">
                                            <CarouselButton direction="left" onClick={slidePrev} />
                                        </StyledCarouselButtonContainer>
                                    )}
                                    {hasNext && (
                                        <StyledCarouselButtonContainer alignment="right">
                                            <CarouselButton direction="right" onClick={slideNext} />
                                        </StyledCarouselButtonContainer>
                                    )}
                                    <Swiper
                                        {...swiperConfig}
                                        onSwiper={(instance) => {
                                            setSwiperInstance(instance);
                                        }}
                                    >
                                        {mediaComponents.map((item, index) => (
                                            <SwiperSlide key={index}>{item}</SwiperSlide>
                                        ))}
                                    </Swiper>
                                </StyledRelativeContainer>
                            </Gutter>
                        </MaxWidth>
                    </StyledCarouselWrapper>
                </Fragment>
            ) : (
                <MaxWidth>
                    <Gutter>
                        {headline && <ModuleHeader headline={headline} />}
                        <StyledGrid columns={columns} rows={numberOfRows} height={height}>
                            {mediaComponents}
                        </StyledGrid>
                    </Gutter>
                </MaxWidth>
            )}

            {<ModalGallery items={mediaItems} galleryId={galleryId} />}
        </ModuleContainer>
    ) : null;
};

export default withErrorBoundary(M70Media);

const swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    autoplay: false,
    spaceBetween: 30,
    speed: 500,
    slidesPerGroupAuto: true,
    mousewheel: {
        forceToAxis: true, // Enables horizontal scroll, but prevents carousel from hijacking regular vertical scroll
    },
    a11y: {
        enabled: false, // Fixes jumps when clicking slide outside natural viewport
    },
    preventClicksPropagation: true, // Fix buttons and links inside carousel causing a jump when dragged.
};
