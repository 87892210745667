import styled from '@emotion/styled';
import { transparentize } from 'color2k';
import { ifProp } from 'styled-tools';
import { breakpoints } from '$theme';

export const StyledMediaItem = styled.div<{
    height: 'small' | 'large';
    isCarousel: boolean;
    hideShowGalleryButton?: boolean;
}>(
    {
        position: 'relative',
        height: '100%',
    },
    ifProp(
        'isCarousel',
        ({ height, theme }) => ({
            height: theme.moduleHeight[height],
        }),
        {
            cursor: 'pointer',
        }
    ),
    ifProp('hideShowGalleryButton', {
        cursor: 'default',
    })
);

export const StyledImageWrapper = styled.div<{ width: number; isCarousel: boolean }>(
    ({ width, isCarousel, theme }) => ({
        position: 'relative',
        height: '100%',
        width: isCarousel ? width : '100%',
        maxWidth: `calc(100vw - ${theme.spaces.mobileGutter * 2}px)`,
        borderRadius: '45px 15px',
        overflow: 'hidden',

        [breakpoints.sm]: {
            maxWidth: 'unset',
        },
    })
);

export const StyledVideoWrapper = styled.div<{ isCarousel: boolean }>(({ isCarousel }) => ({
    position: 'relative',
    height: '100%',
    width: isCarousel ? 520 : '100%',
    overflow: 'hidden',
    borderRadius: '45px 15px',
    zIndex: 1,
}));

export const StyledVideoInnerWrapper = styled.div<{ width: number; isCarousel: boolean }>({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    width: '100%',
    video: {
        objectFit: 'cover',
    },
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});

export const StyledPlayerButton = styled.div(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    width: 100,
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)',
    minWidth: 100,
    borderRadius: '50%',
    color: theme.colors.black100,
    backgroundColor: transparentize(theme.colors.white100, 0.2),
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: transparentize(theme.colors.white100, 0.1),
    },
}));
